import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import '@/plugin/keycloak';

Vue.use(VueRouter)

const routes = [
  {
    path: '',
    component: () => import('../App.vue'),
    children: [
      {
        path: '/login',
        name: 'Login',
        component: () => import('../views/LoginBefore.vue'),
      },
      {
        path: '',
        component: Home,
        children: [
          {
            path: '/',
            name: 'home',
            component: () => import('../views/Dashboard.vue')
          },
          {
            path: '/referral',
            name: 'ReferralPage',
            component: () => import('../views/Rujukan.vue')
          },
        ]
      },
      {
        path: '/register',
        name: 'Register',
        component: () => import('../views/LoginPage.vue'),
      },
      {
        path: '/pengaturan',
        name: 'pengaturan',
        component: () => import('../views/Pengaturan.vue')
      },
      {
        path: '/profileEdit',
        name: 'profileEdit',
        component: () => import('../views/ProfileEdit.vue')
      },
    ]
  },
  {
    path: '/linked',
    name: 'link-permission',
    component: () => import('../views/Link.vue')
  },
  {
    path: '/error',
    name: 'error',
    component: () => import('../views/Error.vue'),
  }
]

const router = new VueRouter({
  routes,
  mode: 'history'
})

router.beforeEach((to, from, next) => {
  next()
})

export default router
