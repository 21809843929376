import Vue from 'vue';
import Keycloak from 'keycloak-js';

const initOptions = {
    url: process.env.VUE_APP_URL_SSO,
    clientId: process.env.VUE_APP_CLIENT_ID_SSO,
    onLoad  : 'login-required',
    "realm": process.env.VUE_APP_REALM_SSO,
    "auth-server-url": process.env.VUE_APP_URL_SSO,
    "ssl-required": "external",
    "resource": process.env.VUE_APP_CLIENT_ID_SSO,
    "public-client": true,
    "confidential-port": 0
};

const _keycloak = Keycloak(initOptions);

const Plugin = {
    install: Vue => {
        Vue.$keycloak = _keycloak;
    },
};
Plugin.install = Vue => {
    Vue.$keycloak = _keycloak;
    Object.defineProperties(Vue.prototype, {
        $keycloak: {
            get() {
                return _keycloak;
            },
        },
    });
};

Vue.use(Plugin);

export default Plugin;