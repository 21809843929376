import axios from "axios";
// import router from '../router'

var axiosInstance = axios.create({
  baseURL: `${process.env.VUE_APP_BASE_URL}`,
  headers: {
    // access_token : localStorage.token
    Authorization: `Bearer ${localStorage.token}`,
    // 'X-TenantId': tenantId
  }
})


axiosInstance.interceptors.request.use((config) => {
  
  let token = localStorage.token
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config
}, (error) => {
  console.log("Interceptor Request Error" + error)
})

axiosInstance.interceptors.response.use((response) => {
  return response
}, (error) => {
  const originalRequest = error.config;
  if(error.response.status == 401 && !originalRequest._retry) {
    // localStorage.removeItem("token");
    originalRequest._retry = true;
    localStorage.setItem('errorToken', 'Token Expired, Silahkan Login kembali')
    keycloak.updateToken(-1)
    .then(function(){
      localStorage.token = keycloak.token
      window.token = keycloak.token
      // window.location.reload()
      return axiosInstance(originalRequest)
    });
  }
  return Promise.reject(error);
})

export default axiosInstance
