<template>
  <div class="home">
    <!-- <div id="nav">
      <router-link to="/">Home</router-link> |
      <router-link to="/referral">referral</router-link>
    </div> -->

    <g id="house" transform="translate(-0.781 -1.887)">
      <path
        id="Path_7"
        data-name="Path 7"
        d="M3.125,21.094V10.938H4.688V21.094a.781.781,0,0,0,.781.781H19.532a.781.781,0,0,0,.781-.781V10.938h1.563V21.094a2.344,2.344,0,0,1-2.344,2.344H5.469a2.344,2.344,0,0,1-2.344-2.344ZM20.313,3.906V9.375L17.188,6.25V3.906a.781.781,0,0,1,.781-.781h1.563a.781.781,0,0,1,.781.781Z"
      />
      <path
        id="Path_8"
        data-name="Path 8"
        d="M11.4,2.344a1.563,1.563,0,0,1,2.209,0L23.99,12.728a.782.782,0,0,1-1.106,1.106L12.5,3.448,2.116,13.834A.782.782,0,1,1,1.01,12.728Z"
      />
    </g>

    <g id="file" transform="translate(-3.125 -1.563)">
      <path
        id="Path_4"
        data-name="Path 4"
        d="M6.25,1.563h7.813V3.126H6.25A1.563,1.563,0,0,0,4.687,4.689V20.314A1.563,1.563,0,0,0,6.25,21.877h12.5a1.563,1.563,0,0,0,1.563-1.563V9.375h1.563V20.313a3.125,3.125,0,0,1-3.125,3.125H6.25a3.125,3.125,0,0,1-3.125-3.125V4.688A3.125,3.125,0,0,1,6.25,1.563Z"
      />
      <path
        id="Path_5"
        data-name="Path 5"
        d="M14.063,7.031V1.563l7.813,7.813H16.407a2.344,2.344,0,0,1-2.344-2.345Z"
      />
      <path
        id="Path_6"
        data-name="Path 6"
        d="M7.813,17.969a.781.781,0,0,1,.781-.781h3.125a.781.781,0,0,1,0,1.563H8.594a.781.781,0,0,1-.781-.781Zm0-3.125a.781.781,0,0,1,.781-.781h7.813a.781.781,0,0,1,0,1.563H8.594a.781.781,0,0,1-.781-.781Zm0-3.125a.781.781,0,0,1,.781-.781h7.813a.781.781,0,0,1,0,1.563H8.594a.781.781,0,0,1-.781-.781Z"
      />
    </g>

    <Navbar />
    <router-view></router-view>
    <!-- {{route}} -->
    <div class="bottomBar">
      <div class="d-flex justify-content-center">
        <a
          :class="'nav-link ' + route('/')"
          id="home"
          href="#"
          @click.prevent="$router.push('/')"
        >
          <svg viewBox="-1 -2 25 25">
            <use xlink:href="#house"></use>
          </svg>
          <span>Home</span>
        </a>
        <a
          :class="'nav-link ' + route('/referral')"
          id="referral"
          href="#"
          @click.prevent="$router.push('/referral')"
        >
          <svg viewBox="-3 -2 25 25">
            <use xlink:href="#file"></use>
          </svg>
          <span>Rujukan</span>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from "@/components/HelloWorld.vue";
import Navbar from "../components/Navbar";
import HomeSvg from "../components/HomeSvg";
import axios from '@/axios'
import { mapMutations, mapState } from 'vuex';
export default {
  props: ['keycloaks'],
  name: "Home",
  components: {
    HelloWorld,
    Navbar,
    HomeSvg,
  },
  computed: {
    ...mapState({
        isRegister: (state) => state.isRegister,
    })
  },
  methods: {
    ...mapMutations({
      'changeIsRegister': 'SET_ISREGISTER'
    }),
    route(rut) {
      if (rut == this.$route.path) {
        return "active";
      }
      return "";
    },
    
  },
  mounted() {
    // console.log('tokenm', this.keycloaks)
    // console.log('token', keycloak)
    // this.currentregistrasi()
    // console.log(this.$route.path);
  },
};
</script>
