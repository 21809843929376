<template>
  <div>
    <nav class="navbar navbar-expand-md ">
      <div class=" order-1 order-md-0 dual-collapse2 ml-2">
        <ul class="navbar-nav mr-auto">
          <li
            class="nav-item active c-pointer"
            @click.prevent="$router.push({ name: 'home' })"
          >
            <!-- <a class="nav-link" href=x"#">Left</a> -->
            <img
              class=""
              alt="Mandaya Logo"
              style="height: 30px;"
              src="./../assets/careDokter.png"
            />
          </li>
        </ul>
      </div>
      <div class="mx-auto order-0" id="middleBar" >
        <ul class="navbar-nav mr-auto" v-if="isRegister">
          <li class="nav-item active" @click.prevent="$router.push('/')">
            <a :class="'nav-link row ' + route('/')" href="#">
              <svg viewBox="-1 -2 25 25">
                <use xlink:href="#house"></use>
              </svg>
              <span>Home</span>
            </a>
          </li>
          <li
            class="nav-item active "
            @click.prevent="$router.push('/referral')"
          >
            <a :class="'nav-link ' + route('/referral')" href="#">
              <svg viewBox="-3 -2 25 25">
                <use xlink:href="#file"></use>
              </svg>
              <span>Rujukan</span>
            </a>
          </li>
        </ul>
      </div>
      <div class=" order-3 dual-collapse2 mr-3">
        <ul class="navbar-nav ml-auto">
          <!-- <li class="nav-item m-0 p-0 mt-1 mr-2">
          </li> -->
          <li class="nav-item dropdown">
            <div
              class="dropdown show"
              style="display: inline-flex;"
            >
              <span class="mr-2 mt-1">{{ $store.state.userName }}</span>
              <i
                class="far fa-user-circle c-pointer"
                style="font-size: 2em; color:grey;"
                id="dropdownMenuLink"
                data-toggle="dropdown"
              >
                <!-- <a class="btn btn-secondary dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    </a> -->
              </i>

              <div
                class="dropdown-menu"
                aria-labelledby="dropdownMenuLink"
                right
              >
                <a
                  class="dropdown-item"
                  target="_blank"
                 :href="accountUrl"
                  ><i class="fas fa-cog mr-1"></i>Pengaturan Account</a
                >
                <a
                  class="dropdown-item"
                  href="#"
                  @click.prevent="$router.push('/pengaturan')"
                  ><i class="fas fa-cog mr-1"></i>Pengaturan Perujuk</a
                >
               
                <a
                  class="dropdown-item"
                  href="#"
                  v-if="$store.state.roleAdmin"
                  data-toggle="modal"
                  data-target="#impersonateModal"
                  ><i class="fas fa-sign-in-alt mr-1"></i>Impersonate</a
                >
                <a class="dropdown-item" href="#" @click.prevent="logout()"
                  ><i class="fas fa-sign-out-alt mr-1"></i>Logout</a
                >
              </div>
            </div>
          </li>
        </ul>
      </div>
    </nav>
    <nav
      class="navbar sticky-top container-fullwidth navbar-dark bg-dark mb-5"
      style="height: 3rem"
      v-if="oldToken()"
    >
      <a class="navbar-brand" href="#"></a>
      <!-- <h5>Impersonate As: {{impersonate.login}}</h5> -->
      <a
        href="#"
        class="col-6 kt-font-sm mb-0 text-right text-light"
        @click.prevent="exitImpersonate()"
      >
        Exit Impersonate
        <i class="fas fa-sign-out-alt"></i>
      </a>
    </nav>

    <g id="house" transform="translate(-0.781 -1.887)">
      <path
        id="Path_7"
        data-name="Path 7"
        d="M3.125,21.094V10.938H4.688V21.094a.781.781,0,0,0,.781.781H19.532a.781.781,0,0,0,.781-.781V10.938h1.563V21.094a2.344,2.344,0,0,1-2.344,2.344H5.469a2.344,2.344,0,0,1-2.344-2.344ZM20.313,3.906V9.375L17.188,6.25V3.906a.781.781,0,0,1,.781-.781h1.563a.781.781,0,0,1,.781.781Z"
      />
      <path
        id="Path_8"
        data-name="Path 8"
        d="M11.4,2.344a1.563,1.563,0,0,1,2.209,0L23.99,12.728a.782.782,0,0,1-1.106,1.106L12.5,3.448,2.116,13.834A.782.782,0,1,1,1.01,12.728Z"
      />
    </g>

    <g id="file" transform="translate(-3.125 -1.563)">
      <path
        id="Path_4"
        data-name="Path 4"
        d="M6.25,1.563h7.813V3.126H6.25A1.563,1.563,0,0,0,4.687,4.689V20.314A1.563,1.563,0,0,0,6.25,21.877h12.5a1.563,1.563,0,0,0,1.563-1.563V9.375h1.563V20.313a3.125,3.125,0,0,1-3.125,3.125H6.25a3.125,3.125,0,0,1-3.125-3.125V4.688A3.125,3.125,0,0,1,6.25,1.563Z"
      />
      <path
        id="Path_5"
        data-name="Path 5"
        d="M14.063,7.031V1.563l7.813,7.813H16.407a2.344,2.344,0,0,1-2.344-2.345Z"
      />
      <path
        id="Path_6"
        data-name="Path 6"
        d="M7.813,17.969a.781.781,0,0,1,.781-.781h3.125a.781.781,0,0,1,0,1.563H8.594a.781.781,0,0,1-.781-.781Zm0-3.125a.781.781,0,0,1,.781-.781h7.813a.781.781,0,0,1,0,1.563H8.594a.781.781,0,0,1-.781-.781Zm0-3.125a.781.781,0,0,1,.781-.781h7.813a.781.781,0,0,1,0,1.563H8.594a.781.781,0,0,1-.781-.781Z"
      />
    </g>

    <ModalImpersonate v-if="$store.state.roleAdmin" />
  </div>
</template>

<script>
import ModalImpersonate from "../components/ModalImpersonate";
import {mapState} from 'vuex'
export default {
  data() {
    return {
      showModal: false,
      accountUrl: process.env.VUE_APP_URL_SSO + '/realms/'+process.env.VUE_APP_REALM_SSO+'/account'
    };
  },
  components: {
    ModalImpersonate,
  },
  computed: {
      ...mapState({
        isRegister: (state) => state.isRegister,
    })
  },
  methods: {
    exitImpersonate() {
      localStorage.setItem("token", localStorage.old_token);
      localStorage.removeItem("old_token");
      this.$router.go("/");
    },
    logout() {
      localStorage.removeItem("token");
      localStorage.removeItem('showKeycloak')
      setTimeout(() => {
        keycloak.logout()
      }, 100);
    },
    oldToken() {
      if (localStorage.old_token) {
        return true;
      }
      return false;
    },
    route(rut) {
      if (rut == this.$route.path) {
        return "active";
      }
      return "";
    },
  },
};
</script>

<style></style>
